@import "src/styles/helpers";

.search {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  padding: 48px 24px;
  overflow-y: auto;
  @include hideScrollbar;
  display: grid;
  align-items: stretch;

  @include media(portrait) {
    padding: 48px 64px;
  }

  @include media(landscape) {
    padding: 87px 80px 64px;
  }

  @include media(desktopAll) {
    padding: 98px 120px 64px;
  }

  &__header {
    display: grid;
    gap: 40px;
  }

  &__content {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    display: grid;
    align-items: stretch;
    grid-template-rows: auto 1fr;
  }

  .quickSearch {
    display: grid;
    gap: var(--margin-sm);

    @include media(desktopAll) {
      grid-template-columns: auto 1fr;
      gap: var(--margin-xl);
      padding-left: var(--margin-md);
    }

    &__content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: var(--margin-xs);

      @include media(desktopAll) {
        justify-content: flex-end;
      }
    }

    &__title {
      font-size: 14px;
      line-height: 2;
      font-weight: 300;
      @include setProperty(color, var(--secondary-200), var(--secondary-500));
    }

    &__chip {
      @include media(mobile) {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}

.searchCloseButton {
  position: fixed;
  z-index: 3;
  right: var(--margin-md);
  top: var(--margin-md);
  width: 40px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-200));

  @include media(desktopAll) {
    right: 52px;
    top: 32px;
  }

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.inputBlock {
  position: relative;
}

.input {
  font-family: 'KharkivTone', sans-serif;
  font-size: 32px;
  line-height: 40px;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  background: transparent;
  border: none;
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-accent), var(--light-line-accent));
  @include setProperty(caret-color, var(--primary-500), var(--primary-900));
  width: 100%;
  padding: 0 50px 8px 16px;
  border-radius: 0;
  outline: none;

  &_error {
    @include setProperty(border-color, var(--dark-error), var(--light-error));
  }

  @include media(desktopAll) {
    padding: 0 60px 8px 24px;
  }

  &::placeholder {
    @include setProperty(color, var(--secondary-600), var(--secondary-100));
  }

  @include media(desktopAll) {
    font-size: 48px;
    line-height: 56px;
  }
}

.autocomplete {
  display: grid;
  padding-top: var(--margin-sm);
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  max-height: 60vh;
  overflow-y: auto;

  @include media(tablet) {
    padding-top: 40px;
  }

  @include media(desktopAll) {
    padding-top: 40px;
  }
}

.autocompleteItem {
  @include setProperty(color, var(--secondary-300), var(--secondary-200));
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  padding: var(--margin-sm);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media(tablet) {
    padding: var(--margin-sm) var(--margin-md);
  }

  @include media(desktopAll) {
    padding: var(--margin-sm) var(--margin-md);
  }

  mark {
    font-weight: 500;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  &:hover {
    @include setProperty(background, var(--secondary-800), var(--secondary-50));
  }

  &:before {
    content: '';
    background: url('/media/search_autocomplete.svg') no-repeat center / contain;
    width: 20px;
    height: 20px;
    margin-right: var(--margin-sm);
  }
}

.results {
  margin-top: var(--margin-lg);
  display: grid;
  gap: 40px;

  @include media(tablet) {
    margin-top: 120px;
    gap: var(--margin-xl);
  }

  @include media(desktopAll) {
    margin-top: 120px;
    gap: var(--margin-xl);
    padding-left: var(--margin-md);
  }
}

.resultSection {
  padding-bottom: var(--margin-xl);
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));

  @include media(desktopAll) {
    display: grid;
    gap: var(--margin-md);
    align-items: flex-start;
    justify-content: space-between;
    grid-template-columns: 1fr 712px;
  }

  @include media(desktopBig) {
    grid-template-columns: 1fr 850px;
  }

  @include media(desktopHuge) {
    grid-template-columns: 1fr 850px;
  }

  &__header {
    margin-bottom: var(--margin-md);

    @include media(tablet) {
      margin-bottom: 40px;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
    }
  }

  &__category {
    font-size: 14px;
    line-height: 2;
    font-weight: 300;
    color: var(--secondary-200);
    margin-bottom: 4px;

    @include media(tablet) {
      font-size: 16px;
    }

    @include media(desktopAll) {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-family: 'KharkivTone', sans-serif;

    @include media(tablet) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__links {
    display: grid;
    gap: var(--margin-xs);
  }

  &__articles {
    display: grid;
    gap: var(--margin-xl);
  }
}

.searchLink {
  padding: var(--margin-sm);
  font-family: 'KharkivTone', sans-serif;
  font-size: 14px;
  line-height: 20px;
  @include setProperty(color, var(--primary-500), var(--secondary-500));
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  @include setProperty(background, var(--secondary-800), var(--secondary-50));

  @include media(tablet) {
    font-size: 16px;
    line-height: 24px;
    padding: var(--margin-sm) var(--margin-md);
  }

  @include media(desktopAll) {
    font-size: 16px;
    line-height: 24px;
    padding: var(--margin-sm) var(--margin-md);
  }

  &:hover {
    @include setProperty(background, var(--secondary-700), var(--secondary-50));
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.loadMoreButton {
  margin: var(--margin-lg) auto 0;

  @include media(portrait) {
    margin-top: 58px;
    margin-left: 0;
  }

  @include media(landscape) {
    margin-top: 58px;
    margin-right: 0;
  }

  @include media(desktopAll) {
    margin-top: 58px;
    margin-right: 0;
  }
}

.emptyResults {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;

  @include media(mobile) {
    margin-top: calc(120px - 32px);
    margin-bottom: 120px;
  }

  &__text {
    font-size: 14px;
    line-height: 2;
    font-weight: 300;
    text-align: center;
    max-width: 631px;
  }

  &__button {
    margin: 0 auto;
  }
}

.searchForm {
  position: relative;
}

.searchLoadingIcon {
  position: absolute;
  z-index: 2;
  top: calc(50% - 16px);
  right: 16px;
  width: 24px;
  height: 24px;
  animation: spin 1.5s linear infinite;

  @include media(desktopAll) {
    width: 32px;
    height: 32px;
    top: calc(50% - 20px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
