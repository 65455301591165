@import 'src/styles/helpers';

.submenu {
  position: absolute;
  top: 79px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  @include setProperty(background, var(--secondary-800), var(--light-bg));
  @include setProperty(box-shadow, 0px 19px 30px 0px rgba(16, 16, 16, 0.97), 0px 19px 30px 0px rgba(195, 195, 195, 0.44));
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  transition: .3s ease all;
  cursor: default;

  &:before {
    content: '';
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% + 100px);
    z-index: 0;
  }

  @include media(landscape) {
    &::before {
      top: -36px;
    }
  }

  @include media(desktopSmall) {
    width: calc(100% - 52px);
  }

  @include media(tablet) {
    width: calc(100% - 52px);
  }

  &.isVisible {
    visibility: visible;
    opacity: 1;
    transition: .6s ease visibility, .6s ease opacity;
  }

  &__mainlist {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &__item {
      padding: 32px;
    }
  }

  &__sublist {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    &__item {
      display: flex;
      align-items: center;
      gap: 12px;

      .imageWrapper {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }

      .image {
        position: relative;
        width: 24px;
        height: 24px;
        transition: .3s ease all;
      }

      .title {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        font-family: 'KharkivTone', sans-serif;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        transition: .3s ease all;
      }

      &:hover {
        .imageWrapper {
          @include setProperty(background, linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.04) 35.32%, rgba(246, 255, 254, 0.00) 118.57%), transparent);
        }

        .image {
          transform: scale(1.25);
        }

        .title {
          @include setProperty(color, var(--primary-500), var(--primary-900));
        }
      }
    }
  }
}

.exploreAll {
  border-radius: 8px 0 0 8px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  line-height: 28px;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  @include setProperty(background, (linear-gradient(90deg, rgba(247, 255, 254, 0) -33.37%, rgba(155, 188, 183, 0.0408) 35.32%, rgba(247, 255, 254, 0) 118.57%)), (linear-gradient(90deg, rgba(247, 255, 254, 0) -60.24%, rgba(155, 188, 183, 0.2) 15%, rgba(247, 255, 254, 0) 115%)));

  .icon {
    width: 64px;
    height: 48px;
    position: relative;
  }

  .title {
    margin-top: 16px;
    font-family: 'KharkivTone', sans-serif;
    position: relative;
    font-weight: 400;
    text-align: left;
    max-width: 160px;
    font-size: 20px;
  }

  .subTitle {
    margin-top: 16px;
    max-width: 300px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14px;
  }

  .allServicesButton {
    margin-top: 28px;
    padding-left: 0px;
  }
}