@import 'src/styles/helpers';

.menuItem {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  @include setProperty(color, var(--secondary-100), var(--secondary-400));
  font-size: 14px;
  line-height: 20px;

  &.linkless {
    position: static !important;

    .submenu {
      width: 100%;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      .submenu, .dropdown {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &.dropdownButton {
    position: relative !important;

    &:hover {
      .dropdown {
        display: grid;
      }
    }
  }

  .dropdownIcon {
    width: 22px;
    height: 22px;
    transition: .6s ease transform;
  }

  &:hover,
  &.active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
    position: relative;

    .dropdownIcon {
      transform: rotate(-180deg);
    }
  }
}

.menu {
  margin-right: 40px;

  @include media(mobile) {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--margin-md);

    &.disabled {
      display: none;
      pointer-events: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
  }
}

.dropdown {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease all;
}
