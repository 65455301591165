@import 'src/styles/helpers';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 24px;
  transition: all 0.3s;

  @include media(desktopAll) {
    padding: 32px 52px;
  }

  @include media(desktopBig) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include media(desktopHuge) {
    padding-left: 80px;
    padding-right: 80px;
  }

  &_absolute {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
  }
  
  &_fixed {
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    @include setProperty(background-color, rgba(20, 20, 20, 0.72), rgba(245, 245, 245, 0.72));
    backdrop-filter: blur(12px);

    section {
      top: 100%;

      &::before {
        top: -26px;
      }
    }
  }

  &__rightBlock {
    display: flex;
    align-items: center;

    @include media(mobile) {
      display: none;
    }

    @include media(portrait) {
      display: none;
    }

    @include media(landscape) {
      display: none;
    }
  }
}

.logo {
  margin-right: auto;
}

.menuButton {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 40px;
  display: none;
  justify-content: center;
  gap: 6px;

  @include media(mobile) {
    display: grid;
    width: 24px;
  }

  @include media(portrait) {
    display: grid;
  }

  @include media(landscape) {
    display: grid;
  }

  &:hover {
    .menuButton__line {
      @include setProperty(background, var(--secondary-50), var(--primary-900));
    }
  }

  &_active {

    .menuButton__line {
      @include setProperty(background, var(--primary-500), var(--primary-900));
      margin-left: 3px;

      &:first-child {
        transform-origin: top left;
        transform: rotate(30deg) translateY(-1.5px);
      }

      &:last-child {
        transform-origin: bottom left;
        transform: rotate(-30deg) translateY(1.5px);
      }
    }
  }

  &__line {
    width: 24px;
    height: 1px;
    @include setProperty(background, var(--secondary-200), var(--secondary-500));
    transition: all 0.3s ease;

    @include media(notMobile) {
      width: 40px;
    }
  }
}

.searchButton {
  width: 24px;
  height: 24px;
  @include setProperty(color, var(--secondary-100), var(--secondary-400));
  margin-right: 40px;

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}