@import "src/styles/helpers";

.dropdown {
  cursor: default;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 45px;
  padding: 20px;
  display: grid;
  border-radius: 8px;
  @include setProperty(background, var(--secondary-800), var(--light-bg));
  @include setProperty(box-shadow, 0px 19px 30px 0px rgba(16, 16, 16, 0.97), 0px 19px 30px 0px rgba(195, 195, 195, 0.44));
  min-width: 150px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -24px;
    height: calc(100% + 48px);
    background-color: transparent;
    width: 100%;
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: -12px;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent;
    @include setProperty(border-bottom-color, var(--secondary-800), var(--light-bg));
    transform: rotate(0deg);
  }
}

.link {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px 12px 12px;
  position: relative;
  z-index: 1;

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
